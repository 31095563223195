<template>
  <div id="page-user-view">
    <vs-alert
      color="danger"
      title="Customer Not Found"
      :active.sync="supplier_not_found"
    >
      <span>Supplier record with id: {{ $route.params.id }} not found. </span>
      <span>
        <span>Check </span
        ><router-link :to="{ name: 'suppliers' }" class="text-inherit underline"
          >All Suppliers</router-link
        >
      </span>
    </vs-alert>

    <div id="supplier-data" v-if="data">
      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2 mb-base">
          <vx-card class="mb-base">
            <!-- Avatar -->
            <div class="vx-row">
              <!-- Information - Col 1 -->
              <div class="vx-col flex-1" id="account-info-col-1">
                <table>
                  <tr>
                    <td class="font-semibold">Name</td>
                    <td>{{ data.supplier_name }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Phone</td>
                    <td>
                      <a :href="`tel:${data.supplier_phone}`">{{
                        data.supplier_phone
                      }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Address</td>
                    <td>{{ data.supplier_address }}</td>
                  </tr>

                  <tr>
                    <td class="font-semibold">Date Created</td>
                    <td>{{ parseDate(data.date_created) }}</td>
                  </tr>
                  <tr>
                    <td class="font-semibold">Date Updated</td>
                    <td>{{ parseDate(data.date_updated) }}</td>
                  </tr>
                </table>
              </div>
              <!-- /Information - Col 1 -->
            </div>
          </vx-card>
        </div>

        <div class="vx-col w-full lg:w-1/2">
          <div class="vx-row">
            <div class="vx-col w-full lg:w-1/2">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="CheckCircleIcon"
                icon-right
                :statistic="fulfilledPurchases"
                statisticTitle="Fulfilled Purchases"
                color="success"
              />
            </div>
            <div class="vx-col w-full lg:w-1/2">
              <statistics-card-line
                hideChart
                class="mb-base"
                icon="ClockIcon"
                icon-right
                :statistic="pendingPurchases"
                statisticTitle="Pending Purchases"
                color="warning"
              />
            </div>
          </div>
        </div>
      </div>

      <div id="data-list-list-view" class="data-list-container">
        <vs-table
          ref="table"
          :max-items="itemsPerPage"
          :data="purchases"
          pagination
          search
        >
          <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
          >
            <div
              class="flex flex-wrap-reverse items-center data-list-btn-container"
            >
              <!-- ADD NEW -->
              <div
                v-shortkey.once="['ctrl', 'alt', 'p']"
                class="btn-add-new p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-center text-lg font-medium text-base text-primary border border-solid border-primary"
                @click="addNewData"
                @shortkey="addNewData"
              >
                <feather-icon icon="PlusIcon" svg-classes="h-4 w-4" />
                <span class="ml-2 text-base text-primary">Add New</span>
              </div>
            </div>

            <!-- ITEMS PER PAGE -->
            <vs-dropdown
              vs-trigger-click
              class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
              <div
                class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                  {{
                    purchases.length - currentPage * itemsPerPage > 0
                      ? currentPage * itemsPerPage
                      : purchases.length
                  }}
                  of {{ queriedItems }}</span
                >
                <feather-icon icon="ChevronDownIcon" svg-classes="h-4 w-4" />
              </div>
              <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
              <vs-dropdown-menu>
                <vs-dropdown-item @click="itemsPerPage = 4">
                  <span>4</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 10">
                  <span>10</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 15">
                  <span>15</span>
                </vs-dropdown-item>
                <vs-dropdown-item @click="itemsPerPage = 20">
                  <span>20</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </div>

          <template slot="thead">
            <vs-th sort-key="branch">Branch</vs-th>
            <vs-th sort-key="date">Date</vs-th>
            <vs-th sort-key="products">Items Count</vs-th>
            <vs-th sort-key="amount">Amount</vs-th>
            <vs-th sort-key="fulfilled">Status</vs-th>
            <vs-th>Action</vs-th>
          </template>

          <template>
            <tbody>
              <vs-tr
                v-for="(tr, indextr) in purchases"
                :data="tr"
                :key="indextr"
              >
                <vs-td>
                  <p class="font-medium truncate">
                    {{ getBranch(tr.branch).branch_name || 'N/A' }}
                  </p>
                </vs-td>

                <vs-td>
                  <p class="font-medium truncate">
                    {{ parseDate(tr.date_created) }}
                  </p>
                </vs-td>

                <vs-td>
                  <p class="font-medium truncate">
                    {{ Number(tr.products.length || 0).toLocaleString() }}
                  </p>
                </vs-td>

                <vs-td>
                  <p class="font-medium truncate">
                    {{ Number(tr.amount).toLocaleString('en-KE', {
                    style: 'currency',
                    currency: $root.businessData.data.currency
                  }) }}
                  </p>
                </vs-td>

                <vs-td>
                  <vs-chip
                    :color="tr.fulfilled ? 'success' : 'danger'"
                    class="method-status"
                    >{{ tr.fulfilled ? 'FULFILLED' : 'PENDING' }}</vs-chip
                  >
                </vs-td>

                <vs-td class="whitespace-no-wrap">
                  <feather-icon
                    icon="FileTextIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="getReceipt(tr._id)"
                  />
                </vs-td>
              </vs-tr>
            </tbody>
          </template>
        </vs-table>
      </div>
    </div>
  </div>
</template>

<script>
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue';
export default {
  components: {
    StatisticsCardLine,
  },
  data() {
    return {
      supplier_not_found: false,
      database: null,

      selected: [],
      itemsPerPage: 10,
      isMounted: false,
    };
  },
  pouch: {
    supplierData() {
      return {
        database: 'express',
        selector: { type: 'supplier', _id: this.$route.params.id },
        first: true,
      };
    },
    purchasesData() {
      if (this.user && this.user.role == 'admin') {
        return {
          database: 'express',
          selector: {
            type: 'purchase',
            supplier: this.$route.params.id,
          },
          sort: [
            {
              date_updated: 'desc',
            },
          ],
        };
      }
      return [];
    },
    branchData() {
      return {
        database: 'express',
        selector: { type: 'branch' },
      };
    },
  },
  computed: {
    user() {
      return this.$session.get('user');
    },
    currentPage() {
      if (this.isMounted && 'table' in this.$refs) {
        return this.$refs.table.currentx || 0;
      }
      return 0;
    },
    purchases() {
      return this.purchasesData ? this.purchasesData : [];
    },
    branches() {
      return this.branchData ? this.branchData : [];
    },
    data() {
      if (!this.supplierData) {
        this.supplier_not_found = true;
        return null;
      }
      this.supplier_not_found = false;
      return this.supplierData;
    },
    sales() {
      return this.user.role == 'admin'
        ? this.saleData || []
        : this.mySaleData || [];
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.sales.length;
    },
    fulfilledPurchases() {
      let fulfilledPurchases = 0;
      this.purchases.forEach((p) => {
        if (p.fulfilled) {
          fulfilledPurchases++;
        }
      });

      return fulfilledPurchases;
    },
    pendingPurchases() {
      let pendingPurchases = 0;

      this.purchases.forEach((p) => {
        if (!p.fulfilled) {
          pendingPurchases++;
        }
      });

      return pendingPurchases;
    },
  },
  methods: {
    getBranch(id) {
      return this.branches.find((e) => e._id == id) || {};
    },
    parseDate(date) {
      return this.$moment(date).format('ddd DD MMM YYYY, h:mm:ss a');
    },
    addNewData() {
      this.$router.push('/store/purchases/new').catch(() => {});
    },
    getReceipt(id) {
      this.$router.push(`/store/purchases/${id}/receipt`).catch(() => {});
    },
  },
  mounted() {
    this.isMounted = true;
  },
  async created() {
    // var db = await idb.getRecord("database");
  },
};
</script>

<style lang="scss">
#page-user-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: 0.8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width: 370px) {
          display: block;
        }
      }
    }
  }
}

@media screen and (min-width: 1201px) and (max-width: 1211px),
  only screen and (min-width: 636px) and (max-width: 991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  /* #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // } */
}

#data-list-list-view {
  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .sale-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 20px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
